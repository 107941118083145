<template>
  <v-container>
    <v-row>
      <h4 class="System_admin_text_Product ml-2">
        ESTIMATING THE BUSINESS VALUATION MULTIPLE
      </h4>
    </v-row>

    <v-row id="Deal">
      <v-col cols="12" sm="3">
        <p>
          <span
            ><b>DEAL: </b>
            <span
              ><b>{{ this.currentDeal }}</b></span
            ></span
          >
        </p>
      </v-col>
    </v-row>

    <v-row id="Button Group">
      <v-col cols="9">
        <div class="text-right">
          <b-button-group>
            <!-- <button class="offers-button" @click="openOffers">Offers</button> -->
            <!-- <button class="tasks-button" @click="openTasks">Tasks</button> -->
            <b-button class="uploads-button" @click="openUploads"
              >Uploads</b-button
            >
            <b-button class="save-button" @click="saveFormData">Save</b-button>
            <b-button class="back-button" @click="goBack">Back</b-button>
            <!-- <WordExportButton
                templateName="CBR"
                :templateData="wsData"
                /> -->
          </b-button-group>
        </div>
      </v-col>
    </v-row>

    <v-row id="Multiple Table">
      <div class="col-md-12" style="margin-top: 20px">
        <v-simple-table style="border-radius: 7px; border: 1px solid #c4c4c4">
          <template>
            <thead style="color: azure">
              <tr>
                <th class="header-black-and-white">VALUATION QUESTION</th>
                <th class="header-black-and-white" style="text-align: right">
                  RESPONSE
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  :title="wsData[0].description"
                >
                  {{ wsData[0].question_text }}
                </td>
                <td style="background-color: yellow">
                  <b-form-radio-group
                    v-model="wsData[0].question_answer"
                    :options="options_answers"
                    @change="toggleCashflow(0)"
                    name="radio-Status"
                  ></b-form-radio-group>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  :title="wsData[1].description"
                >
                  {{ wsData[1].question_text }}
                </td>
                <td style="background-color: yellow">
                  <b-form-radio-group
                    v-model="wsData[1].question_answer"
                    :options="options_answers"
                    @change="toggleCashflow(1)"
                    name="radio-Status-1"
                  ></b-form-radio-group>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  :title="wsData[2].description"
                >
                  {{ wsData[2].question_text }}
                </td>
                <td style="background-color: yellow">
                  <b-form-radio-group
                    v-model="wsData[2].question_answer"
                    :options="options_answers"
                    @change="toggleCashflow(2)"
                    name="radio-Status-2"
                  ></b-form-radio-group>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  :title="wsData[3].description"
                >
                  {{ wsData[3].question_text }}
                </td>
                <td style="background-color: yellow">
                  <b-form-radio-group
                    v-model="wsData[3].question_answer"
                    :options="options_answers"
                    @change="toggleCashflow(3)"
                    name="radio-Status-3"
                  ></b-form-radio-group>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  :title="wsData[4].description"
                >
                  {{ wsData[4].question_text }}
                </td>
                <td style="background-color: yellow">
                  <b-form-radio-group
                    v-model="wsData[4].question_answer"
                    :options="options_answers"
                    @change="toggleCashflow(4)"
                    name="radio-Status-4"
                  ></b-form-radio-group>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  :title="wsData[5].description"
                >
                  {{ wsData[5].question_text }}
                </td>
                <td style="background-color: yellow">
                  <b-form-radio-group
                    v-model="wsData[5].question_answer"
                    :options="options_answers"
                    name="radio-Status-5"
                  ></b-form-radio-group>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  :title="wsData[6].description"
                >
                  {{ wsData[6].question_text }}
                </td>
                <td style="background-color: yellow">
                  <b-form-radio-group
                    v-model="wsData[6].question_answer"
                    :options="options_answers"
                    name="radio-Status-6"
                  ></b-form-radio-group>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  :title="wsData[7].description"
                >
                  {{ wsData[7].question_text }}
                </td>
                <td style="background-color: yellow">
                  <b-form-radio-group
                    v-model="wsData[7].question_answer"
                    :options="options_answers"
                    name="radio-Status-7"
                  ></b-form-radio-group>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  :title="wsData[8].description"
                >
                  {{ wsData[8].question_text }}
                </td>
                <td style="background-color: yellow">
                  <b-form-radio-group
                    v-model="wsData[8].question_answer"
                    :options="options_answers"
                    name="radio-Status-8"
                  ></b-form-radio-group>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  :title="wsData[9].description"
                >
                  {{ wsData[9].question_text }}
                </td>
                <td style="background-color: yellow">
                  <b-form-radio-group
                    v-model="wsData[9].question_answer"
                    :options="options_answers"
                    name="radio-Status-9"
                  ></b-form-radio-group>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  :title="wsData[10].description"
                >
                  {{ wsData[10].question_text }}
                </td>
                <td style="background-color: yellow">
                  <b-form-radio-group
                    v-model="wsData[10].question_answer"
                    :options="options_answers"
                    @change="toggleOwnerInvolement(10)"
                    name="radio-Status-10"
                  ></b-form-radio-group>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  :title="wsData[11].description"
                >
                  {{ wsData[11].question_text }}
                </td>
                <td style="background-color: yellow">
                  <b-form-radio-group
                    v-model="wsData[11].question_answer"
                    :options="options_answers"
                    @change="toggleOwnerInvolement(11)"
                    name="radio-Status-11"
                  ></b-form-radio-group>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  :title="wsData[12].description"
                >
                  {{ wsData[12].question_text }}
                </td>
                <td style="background-color: yellow">
                  <b-form-radio-group
                    v-model="wsData[12].question_answer"
                    :options="options_answers"
                    name="radio-Status-12"
                  ></b-form-radio-group>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  :title="wsData[13].description"
                >
                  {{ wsData[13].question_text }}
                </td>
                <td style="background-color: yellow">
                  <b-form-radio-group
                    v-model="wsData[13].question_answer"
                    :options="options_answers"
                    name="radio-Status-13"
                  ></b-form-radio-group>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  :title="wsData[14].description"
                >
                  {{ wsData[14].question_text }}
                </td>
                <td style="background-color: yellow">
                  <b-form-radio-group
                    v-model="wsData[14].question_answer"
                    :options="options_answers"
                    name="radio-Status-14"
                  ></b-form-radio-group>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  :title="wsData[15].description"
                >
                  {{ wsData[15].question_text }}
                </td>
                <td style="background-color: yellow">
                  <b-form-radio-group
                    v-model="wsData[15].question_answer"
                    :options="options_answers"
                    @change="toggleYearsInBiz(15)"
                    name="radio-Status-15"
                  ></b-form-radio-group>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  :title="wsData[16].description"
                >
                  {{ wsData[16].question_text }}
                </td>
                <td style="background-color: yellow">
                  <b-form-radio-group
                    v-model="wsData[16].question_answer"
                    :options="options_answers"
                    @change="toggleYearsInBiz(16)"
                    name="radio-Status-16"
                  ></b-form-radio-group>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  :title="wsData[17].description"
                >
                  {{ wsData[17].question_text }}
                </td>
                <td style="background-color: yellow">
                  <b-form-radio-group
                    v-model="wsData[17].question_answer"
                    :options="options_answers"
                    @change="toggleYearsInBiz(17)"
                    name="radio-Status-17"
                  ></b-form-radio-group>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  :title="wsData[18].description"
                >
                  {{ wsData[18].question_text }}
                </td>
                <td style="background-color: yellow">
                  <b-form-radio-group
                    v-model="wsData[18].question_answer"
                    :options="options_answers"
                    name="radio-Status-18"
                  ></b-form-radio-group>
                </td>
              </tr>
              <tr class="gray">
                <td
                  style="border-right: 1px solid #c4c4c4"
                  :title="wsData[0].description"
                >
                  Lower Range Multiple
                </td>
                <td style="background-color: greenyellow">
                  {{ usCurrency.format(this.lowerRangeMultiple) }}
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">
                  Higher Range Multiple
                </td>
                <td style="background-color: greenyellow">
                  {{ usCurrency.format(this.higherRangeMultiple) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-row>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import CommonServices from "../service/CommonServices";
import { mask } from "vue-the-mask";

export default {
  directives: {
    mask,
  },

  created() {
    this.checkPermissions();   

    //this.checkPermissions();
    this.logActivity("Opened", localStorage.getItem("DealID"));

    this.usCurrency = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    this.getDealData();
  },

  data() {
    return {
      componentURL: `Multiple/?deal_id=${this.$route.params.id}`,
      componentURL_base: `Multiple`,
      canpost: localStorage.getItem("canpost"),
      candelete: localStorage.getItem("candelete"),
      canput: localStorage.getItem("canput"),
      isAdmin: localStorage.getItem("Admin"),
      isBroker: localStorage.getItem('role')==2,
      isSeller: localStorage.getItem('role')==3,  
      isBuyer: localStorage.getItem('role')==4,
      isDIY: localStorage.getItem('role')==7,
      role: localStorage.getItem('role'),
      valid: false,
      dialog: false,
      dialogDelete: false,
      dialogSelectUser: false,
      deletedId: null,
      snackbar: false,
      snackColor: "",
      snackText: "",
      error: false,
      loading: false,
      editedIndex: -1,
      searchText: "",
      file: "",
      profileImageUrl: "",
      currentDeal: localStorage.getItem("Deal"),
      show: false,
      variants: [
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      headerBgVariant: "success",
      headerTextVariant: "light",
      bodyBgVariant: "light",
      bodyTextVariant: "dark",
      footerBgVariant: "success",
      footerTextVariant: "dark",
      errorTitle: "",
      errorText: "",
      dlgError: false,
      usCurrency: null,

      workYear: new Date().getFullYear() - 1,
      companyName: "",

      items: [],
      list_years: [
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
      ],

      options_answers: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ],

      wsData: {
        id: "",
        deal_id: this.$route.params.id,
        company_name: localStorage.getItem("Deal"),
        work_year: new Date().getFullYear() - 1,
        question_text: "",
        description: "",
        question_answer: null,
        multiple: null,
      },

      userActivityItem: {
        user_id: localStorage.getItem("userid"),
        logdate: this.$moment(String(Date())).format("YYYY-MM-DDThh:mm"),
        page: "CashFlowCalculator",
        action: "",
        deal_id: "",
        json: "",
      },

      mask: {
        maxDollar: "############",
        zipCode: "#####",
        ssn: "###-##-####",
        value: "######",
        phone: "(###) ###-####",
      },
    };
  },

  computed: {
    lowerRangeMultiple() {
      if (this.wsData) {
        return 2.25 + this.valuationTotal * 0.5;
      } else {
        return 0;
      }
    },

    higherRangeMultiple() {
      if (this.wsData) {
        if (this.valuationTotal + 2.5 > 5) {
          return 5;
        } else {
          return this.valuationTotal + 2.5;
        }
      } else {
        return 0;
      }
    },

    valuationTotal() {
      if (this.wsData) {
        return (
          this.wsData[0].question_answer * this.wsData[0].multiple +
          this.wsData[1].question_answer * this.wsData[1].multiple +
          this.wsData[2].question_answer * this.wsData[2].multiple +
          this.wsData[3].question_answer * this.wsData[3].multiple +
          this.wsData[4].question_answer * this.wsData[4].multiple +
          this.wsData[5].question_answer * this.wsData[5].multiple +
          this.wsData[6].question_answer * this.wsData[6].multiple +
          this.wsData[7].question_answer * this.wsData[7].multiple +
          this.wsData[8].question_answer * this.wsData[8].multiple +
          this.wsData[9].question_answer * this.wsData[9].multiple +
          this.wsData[10].question_answer * this.wsData[10].multiple +
          this.wsData[11].question_answer * this.wsData[11].multiple +
          this.wsData[12].question_answer * this.wsData[12].multiple +
          this.wsData[13].question_answer * this.wsData[13].multiple +
          this.wsData[14].question_answer * this.wsData[14].multiple +
          this.wsData[15].question_answer * this.wsData[15].multiple +
          this.wsData[16].question_answer * this.wsData[16].multiple +
          this.wsData[17].question_answer * this.wsData[17].multiple +
          this.wsData[18].question_answer * this.wsData[18].multiple
        );
      } else {
        return 0;
      }
    },
  },

  methods: {
    checkPermissions() {
      if (this.role >3) this.$router.push("/");
    },

    logActivity(activity, dealid, json) {
      this.userActivityItem.action = activity;
      this.userActivityItem.logdate = this.$moment(String(Date())).format(
        "YYYY-MM-DDThh:mm"
      );
      this.userActivityItem.deal_id = dealid;
      this.userActivityItem.json = json;
      CommonServices.postData("UserActivityLog", this.userActivityItem)
        .then((response) => {
          console.log("UserActivityLog Response: ", response.id);
        })
        .catch((error) => {
          console.log("Post Activity Error: ", error);
        });
    },

    getDealData() {
      this.companyName = this.wsData.company_name;
      CommonServices.getList(`Multiple/?deal_id=${this.$route.params.id}`)
        .then((res) => {
          if (res.count == 0) {
            //create records for this deal/year
            this.createQuestions(this.$route.params.id);
          } else {
            //console.log("Current Year: ", this.wsData.work_year);
            //console.log("Yearly Data: ", res);
            this.wsData = res.results;
            this.wsData.company_name = localStorage.getItem("Deal");
          }
          console.log("wsData:", this.wsData);
          console.log("First Question:", this.wsData[0].question_text);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    createQuestions(dealid) {
      console.log("Creating questions for Deal ID:", dealid);
      CommonServices.putData("Functions", {
        id: 6,
        param_one_number: dealid,
        param_one_text: "",
      })
        .then((response) => {
          console.log("Create Questions Response: ", response.id);
          this.getDealData();
          this.wsData.company_name = localStorage.getItem("Deal");
        })
        .catch((error) => {
          console.log("putData Error: ", error);
        });
    },

    resetItems() {
      console.log("Resetting items...");
      this.wsData = {};
    },

    saveFormData() {
      console.log("Last updated: ", this.wsData.last_updated);
      console.log("DealID: ", this.wsData.deal_id);
      if (this.wsData && this.wsData.length && this.wsData[0].id) {
        let payload = {};
        this.wsData.forEach((ele) => {
          payload[ele.id] = ele.question_answer;
        });
        CommonServices.postData(
          `${this.componentURL_base}/update-multiple`,
          payload
        )
          .then(() => {
            this.snackbar = true;
            this.snackColor = "success";
            this.snackText = "Record successfully updated";
            this.logActivity(
              "Saved Form Data",
              this.wsData.deal_id,
              JSON.stringify(this.wsData)
            );
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.response.data).forEach((key) => {
                this.snackText = error.response.data[key][0];
              });
            } catch (e) {
              this.snackText = "Something went wrong";
            }
          });
      } else {
        CommonServices.postData(
          `${this.componentURL_base}/create-multiple`,
          this.wsData
        )
          .then(() => {
            this.snackbar = true;
            this.snackColor = "success";
            this.snackText = "Record successfully added";
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.data).forEach((key) => {
                this.snackText = error.data[key][0];
              });
            } catch (e) {
              this.snackText = "Something went wrong";
            }
          });
      }
    },

    toggleCashflow(id) {
      if (this.wsData[id].question_answer == 1) {
        if (id !== 0) this.wsData[0].question_answer = 0;
        if (id !== 1) this.wsData[1].question_answer = 0;
        if (id !== 2) this.wsData[2].question_answer = 0;
        if (id !== 3) this.wsData[3].question_answer = 0;
        if (id !== 4) this.wsData[4].question_answer = 0;
      }
    },

    toggleYearsInBiz(id) {
      console.log("Toggling Years in Biz...");
      if (this.wsData[id].question_answer == 1) {
        if (id !== 15) this.wsData[15].question_answer = 0;
        if (id !== 16) this.wsData[16].question_answer = 0;
        if (id !== 17) this.wsData[17].question_answer = 0;
      }
    },

    toggleOwnerInvolement(id) {
      console.log("Toggling Owner Involvement...");
      if (this.wsData[id].question_answer == 1) {
        if (id !== 10) this.wsData[10].question_answer = 0;
        if (id !== 11) this.wsData[11].question_answer = 0;
      }
    },

    openTasks() {
      this.$router.push(`/Tasks/${this.$route.params.id}`);
    },

    openOffers() {
      this.$router.push(`/DealOffers/${this.$route.params.id}`);
    },

    openUploads() {
      //First write any missing Deal Folders to the upload table
      CommonServices.putData("Functions", { id: 3 })
        .then((response) => {
          console.log("Put Function Response: ", response.id);
          setTimeout(() => {
            this.$router.push(`/Uploads/${this.$route.params.id}`);
          }, 500);
        })
        .catch((error) => {
          console.log("Put Function Error: ", error);
        });
    },

    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>

<style type="text/css" src="../css/styles.css"></style>
